import React from 'react';
import { MASS_UNITS, VOLUME_UNITS } from "../../constants";

const DoughsForm = ({
  handleMixInput,
  handleMixUnitChange,
  ingredientAmounts,
  handleSelectChange,
  allItems,
  selectedUnits,
  conversionRates,
  setIngredientAmounts,
  handleUnitChange,
  handleRemoveItem,
  handleAddIngredient,
}) => (
  <div>
    <div className="formInput">
      <label >Min Mix </label>
      <input
        id="minMix"
        type="Number"
        placeholder="0"
        onChange={handleMixInput}
      />
      <select
        id="minMix"
        onChange={(e) => {
          console.log("unit select");
          handleMixUnitChange(e);
        }}
      >
        {MASS_UNITS.map((unit, index) => (
          <option
            key={index}
            value={unit.abbreviation}
          >
            {unit.abbreviation}
          </option>
        ))}
      </select>
    </div>
    <div className="formInput">

      <label>Max Mix </label>
      <input
        id="maxMix"
        type="Number"
        placeholder="0"
        onChange={handleMixInput}
      />
      <select
        id="maxMix"
        onChange={(e) => {
          handleMixUnitChange(e);
        }}
      >
        {MASS_UNITS.map((unit, index) => (
          <option
            key={index}
            value={unit.abbreviation}
          >
            {unit.abbreviation}
          </option>
        ))}
      </select>
    </div>
    <div>
      {console.log("ingredientAmounts ", ingredientAmounts)}
      {Array.isArray(ingredientAmounts) ?
        <>
          <h3>Ingredients:</h3>
          {Array.isArray(ingredientAmounts) ? (
            <>
              {ingredientAmounts.map((ingredientAmount, index) => (
                <div key={index}>
                  <select
                    data-key="ingredients"
                    onChange={(e) =>
                      handleSelectChange(
                        e,
                        index,
                        ingredientAmounts,
                        allItems.find(
                          (ingredient) =>
                            ingredient.id === e.target.value
                        )
                      )
                    }
                    value={ingredientAmount.id}
                  >
                    <option value="">Select Ingredient</option>
                    {allItems.map((ingredient, index) => (
                      <option key={index} value={ingredient.id}>
                        {ingredient.name} ({ingredient.collection})
                      </option>
                    ))}
                  </select>

                  {ingredientAmount.id !== "" ? (
                    <>
                      <input
                        type="number"
                        value={
                          selectedUnits[index].isVolume
                            ? ingredientAmount.amount /
                            selectedUnits[index].unit.amount /
                            conversionRates[index]
                            : ingredientAmount.amount /
                            selectedUnits[index].unit.amount
                        }
                        onChange={(e) => {
                          const newIngredientAmounts = [
                            ...ingredientAmounts,
                          ];
                          if (selectedUnits[index].isVolume) {
                            newIngredientAmounts[index].amount =
                              e.target.value *
                              selectedUnits[index].unit.amount *
                              conversionRates[index];
                          } else {
                            newIngredientAmounts[index].amount =
                              e.target.value *
                              selectedUnits[index].unit.amount;
                          }

                          setIngredientAmounts(newIngredientAmounts);
                        }}
                        placeholder="Amount"
                      />{" "}
                      {allItems.find(
                        (item) => item.id === ingredientAmount.id
                      ).volume ? (
                        <select
                          onChange={(e) => handleUnitChange(e, index)}
                        >
                          {MASS_UNITS.map((unit, index) => (
                            <option
                              key={index}
                              value={unit.abbreviation}
                            >
                              {unit.abbreviation}
                            </option>
                          ))}
                          {VOLUME_UNITS.map((unit, index) => (
                            <option
                              key={index}
                              value={unit.abbreviation}
                            >
                              {unit.abbreviation}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <select
                          onChange={(e) => handleUnitChange(e, index)}
                        >
                          {MASS_UNITS.map((unit, index) => (
                            <option
                              key={index}
                              value={unit.abbreviation}
                            >
                              {unit.abbreviation}
                            </option>
                          ))}
                        </select>
                      )}{" "}
                    </>
                  ) : (
                    <>{"  "}</>
                  )}
                  <button
                    type="button"
                    onClick={() => handleRemoveItem(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button type="button" onClick={handleAddIngredient}>
                Add Ingredient
              </button>
            </>
          ) : null}
        </>
        : null}

    </div>

  </div>
);

export default DoughsForm;
